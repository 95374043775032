<template>
  <div
    :style="{backgroundImage: `url(${cover})`}"
    class="CXFeature is-cover-image card"
  >
    <div class="card-content p-b-l p-t-xxl p-l-xl">
      <div class="is-width-1/3">
        <heading color="black" size="4" gapless="">
          {{ title }}
        </heading>
        <div class="m-b-xl">
          {{ content }}
        </div>
        <router-link
          :to="{ name: `cx.${type}` }"
          class="button is-primary is-small"
        >
          {{ $t('general.learn_more') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module CXFeature
 */
export default {
  name: 'CXFeature',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    title () { return this.$t(`pages.cx.features.features.${this.type}.title`) },
    content () { return this.$t(`pages.cx.features.features.${this.type}.content`) },
    cover () { return this.$asset(`/images/cx/features/features/${this.type}.png`) }
  }
}
</script>
