<template>
  <c-x-features
    type="features"
    background="white"
  >
    <div class="p-v-xl">
      <div class="Features columns is-multiline is-variable-grid is-6">
        <div
          v-for="feature in features"
          :key="feature"
          class="column is-6"
        >
          <c-x-feature :type="feature" />
        </div>
      </div>
    </div>
  </c-x-features>
</template>

<script>
import CXFeature from '@/components/cx/CXFeature'
import CXFeatures from '@/router/layouts/CXFeatures'

/**
 * @module Features
 */
export default {
  name: 'Features',
  components: { CXFeature, CXFeatures },
  data () {
    return {
      features: ['measure', 'monitor', 'dashboard', 'connect']
    }
  }
}
</script>
